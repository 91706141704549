import { Pto } from '@outcoreaix/pto'
import { Flex, Spin } from 'antd'
import { useGetWorkspacesOptionsQuery } from 'api/workspaces-api'
import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

type LoaderDataType = { workspaces: Pto.Workspaces.WorkspaceOption[]; spaces: Pto.Spaces.SpaceListItem[] }

const DataRoute = () => {
  const { data, isLoading } = useGetWorkspacesOptionsQuery(undefined, {
    refetchOnMountOrArgChange: false,
    refetchOnFocus: false
  })

  const workspaceMapRole = data?.reduce(
    (a, v) => ({ ...a, [v.id]: v.currentMember?.role || Pto.Users.UserRole.SystemAdmin }),
    {}
  )

  if (isLoading) {
    return (
      <Flex className="w-screen h-dvh lg:h-screen" justify="center" align="center">
        <Spin spinning={true} size="large" />
      </Flex>
    )
  }

  return (
    <Suspense
      fallback={
        <Flex className="w-screen h-dvh lg:h-screen" justify="center" align="center">
          <Spin spinning={true} size="large" />
        </Flex>
      }
    >
      <Outlet context={{ roleMap: workspaceMapRole, spaces: [] }} />
    </Suspense>
  )
}

export default DataRoute
