import { fetchAuthSession, signOut } from 'aws-amplify/auth'
import { toast } from 'react-toastify'
import { Pto } from '@outcoreaix/pto'
import axios from './axios'
import { apiSlice } from 'api/api-splice'
import { store } from './store'

class AuthStorage extends EventTarget {
  public accessToken: string | null = null
  public refreshToken: string | null = null
  public user: Pto.Auth.JwtUser | null = null
  public pendingPhoneVerification: boolean = false
  public phoneNumberForVerification: string | undefined = undefined
  public isRefreshing: boolean = false

  constructor() {
    super()
    this.accessToken = this.getAccessToken() || null
    this.refreshToken = this.getRefreshToken() || null
    this.pendingPhoneVerification = !!this.getTemporaryToken()
    this.phoneNumberForVerification = this.getTemporaryPhone()
    const user = localStorage.getItem('user')

    if (user) {
      this.user = JSON.parse(user)
    }
    this.addEventListener('is-refresh-loading', (event: any) => {
      this.isRefreshing = event.detail
    })
  }

  async login(accessToken: string) {
    try {
      this.pendingPhoneVerification = false
      this.setPendingUserNameSet(false)
      const nonce = this.getInviteToken()
      const { data } = await axios.post<Pto.Auth.JwtUserWithTokens>(`/auth?idToken=${accessToken}&nonce=${nonce}`)
      const { jwtUser: user, tokens } = data
      this.saveSession(user, tokens)
    } catch (error) {
      toast.error('Failed to login')
      this.signOut()
    }
  }

  async loginByToken(token: string) {
    try {
      this.pendingPhoneVerification = false
      this.setPendingUserNameSet(false)
      const { data } = await axios.post<Pto.Auth.JwtUserWithTokens>(`/auth/by-token/${token}`)
      const { jwtUser: user, tokens } = data
      this.saveSession(user, tokens)
    } catch (error) {
      toast.error('Failed to login')
      this.signOut()
    }
  }

  async saveSession(user: Pto.Auth.JwtUser, tokens: Pto.Auth.AuthData) {
    if (!user) {
      toast.error('User not found')
      this.signOut()
      return
    }

    if (!isNaN(parseInt(user.lastName))) {
      this.setPendingUserNameSet(true)
    }

    // if (!user.isPhoneVerified && !user.roles.includes(Pto.Users.UserRole.SystemAdmin)) {
    //   this.setTemporaryToken(accessToken)
    //   this.pendingPhoneVerification = true
    //   this.setTemporaryPhone(user.phone)
    //   this.phoneNumberForVerification = user.phone || undefined
    //   return
    // }

    this.setTemporaryToken()
    this.setInviteToken()
    this.setSpaceToken()
    this.setAccessToken(tokens.accessToken)
    this.setRefreshToken(tokens.refreshToken)
    this.setUser(user)
  }

  setPendingUserNameSet(value: boolean) {
    localStorage.setItem('pendingUserNameSet', value ? 'true' : 'false')
  }

  getPendingUserNameSet() {
    return localStorage.getItem('pendingUserNameSet') === 'true'
  }
  setInviteToken(inviteToken?: string) {
    if (!inviteToken) {
      localStorage.removeItem('inviteToken')
      return
    }

    localStorage.setItem('inviteToken', inviteToken)
  }

  getInviteToken(): string | undefined {
    return localStorage.getItem('inviteToken') || undefined
  }

  setSpaceToken(inviteToken?: string) {
    if (!inviteToken) {
      localStorage.removeItem('spaceToken')
      return
    }

    localStorage.setItem('spaceToken', inviteToken)
  }

  getSpaceToken(): string | undefined {
    return localStorage.getItem('spaceToken') || undefined
  }

  setDomainZones(domainZones?: string[]) {
    if (!domainZones) {
      localStorage.removeItem('domainZones')
      return
    }

    localStorage.setItem('domainZones', domainZones.toString())
  }

  getDomainZones(): string[] | null {
    const domainZones = localStorage.getItem('domainZones')
    return domainZones ? domainZones.split(',') : null
  }

  async fetchSession(forceRefresh: boolean = false) {
    if (!this.accessToken || this.isRefreshing) {
      return
    }

    if (forceRefresh) {
      try {
        this.dispatchEvent(new CustomEvent('is-refresh-loading', { detail: true }))

        let returnedTokens
        if (this.getRefreshToken() === undefined) {
          const { tokens } = await fetchAuthSession({ forceRefresh })

          const { data } = await axios.post<Pto.Auth.AuthData>(`/auth/refresh-by-id-token?idToken=${tokens?.idToken}`)
          returnedTokens = data
        } else {
          const { data } = await axios.post<Pto.Auth.AuthData>('/auth/refresh-token', {
            refreshToken: this.getRefreshToken()
          })
          returnedTokens = data
        }

        const { accessToken, refreshToken } = returnedTokens
        this.setAccessToken(accessToken)
        this.setRefreshToken(refreshToken)
        this.dispatchEvent(new CustomEvent('is-refresh-loading', { detail: false }))
      } catch (e) {
        this.signOut()
        this.dispatchEvent(new CustomEvent('is-refresh-loading', { detail: false }))
        return
      }
    }

    const { data: user } = await axios.get<Pto.Auth.JwtUser>('/users/me')

    if (!user) {
      toast.error('User not found')
      return this.signOut()
    }

    if (!isNaN(parseInt(user.lastName))) {
      this.setPendingUserNameSet(true)
    }

    this.setUser(user)
  }

  setAccessToken(accessToken: string) {
    this.accessToken = accessToken
    localStorage.setItem('accessToken', accessToken.toString())
  }

  getAccessToken(): string | undefined {
    return localStorage.getItem('accessToken') || undefined
  }

  setRefreshToken(refreshToken: string) {
    this.refreshToken = refreshToken
    localStorage.setItem('refreshToken', refreshToken.toString())
  }

  getRefreshToken(): string | undefined {
    return localStorage.getItem('refreshToken') || undefined
  }

  setUser(user: Pto.Auth.JwtUser) {
    // eslint-disable-next-line
    // @ts-ignore
    window.heap?.identify(user.id)
    // eslint-disable-next-line
    // @ts-ignore
    window.heap?.addUserProperties({ Name: `${user.firstName} ${user.lastName}`, email: user.email })
    this.user = user
    localStorage.setItem('user', JSON.stringify(user))
    this.dispatchEvent(new CustomEvent('user-signed-in', { detail: { user } }))
  }

  setTemporaryToken(accessToken?: string) {
    if (!accessToken) {
      localStorage.removeItem('temporaryToken')
      return
    }

    localStorage.setItem('temporaryToken', accessToken)
  }

  getTemporaryToken(): string | null {
    return localStorage.getItem('temporaryToken')
  }

  setTemporaryPhone(phone?: string) {
    if (!phone) {
      localStorage.removeItem('temporaryPhone')
      return
    }

    localStorage.setItem('temporaryPhone', phone)
  }

  getTemporaryPhone(): string | undefined {
    return localStorage.getItem('temporaryPhone') || undefined
  }

  userNameSetNeeded(): boolean {
    return !isNaN(parseInt(this.user?.lastName || ''))
  }

  signOut() {
    store.dispatch(apiSlice.util.resetApiState())

    if (!!this.user) {
      this.dispatchEvent(new CustomEvent('user-signed-out'))
    }

    this.accessToken = null
    this.user = null
    this.pendingPhoneVerification = false
    this.setTemporaryToken()
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('user')
    localStorage.removeItem('inviteToken')
    localStorage.removeItem('spaceToken')

    void signOut()
  }
}

export const authStorage = new AuthStorage()
