import { Input } from 'antd'
import { useSearchParams } from 'react-router-dom'
import type { SearchProps } from 'antd/es/input/Search'
import { FC } from 'react'
import { PaginationKeyEnum } from '@outcoreaix/pto/dist/general'

const Search: FC<SearchProps> = ({ placeholder = 'Search...', onSearch: onSearchSubmit, ...rest }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearchParams({ ...(value && { q: value }) })
    onSearchSubmit?.(value)
  }

  return (
    <Input.Search
      defaultValue={searchParams.get(PaginationKeyEnum.Search) || ''}
      placeholder={placeholder}
      allowClear
      onSearch={onSearch}
      style={{ width: 250 }}
      className="[&_.ant-input-search-button]:!w-[42px]"
      {...rest}
    />
  )
}

export default Search
